@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  margin: 0;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.1875rem;
}

h2 {
  font-size: 1.5625rem;
}

h3 {
  font-size: 1.3125rem;
}

@media screen and (max-width: 1080px) {
  h1 {
    font-size: 1.5625rem;
  }

  h2 {
    font-size: 1.3125rem;
  }

  h3 {
    font-size: 1rem;
  }
}
.card-thumb {
  display: flex;
  flex-direction: column;
  background-color: #d4f6e8;
  min-width: calc(min(100%, 400px));
  max-width: calc(min(100%, 500px));
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-decoration: none;
  box-sizing: border-box;
  color: black;
  box-shadow: 4px 4px 32px 0 rgba(0, 0, 0, 0.16);
  transition: background-color 100ms ease-out, transform 100ms ease-out;
}
.card-thumb:hover {
  background-color: #5cd5b0;
  transform: scale(1.05);
}
.card-thumb__img {
  width: 100%;
}
.card-thumb__content {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 1080px) {
  .card-thumb {
    max-width: 100%;
  }
}
.full-card-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5%;
}
@media (max-width: 1450px) {
  .full-card-controls {
    flex-direction: row;
    width: 100%;
    height: 6vh;
  }
}

.admin-control-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}

.full-card-delete-button {
  background-color: red;
}

.full-card-edit-button {
  background-color: gold;
}

.admin-card-override {
  padding: 0;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 1450px) {
  .admin-card-override {
    flex-direction: column;
  }
}

.full-card-content-container {
  width: 100%;
  background-color: #d4f6e8;
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  justify-content: space-between;
  padding: 1vw;
  align-items: center;
}
@media (max-width: 900px) {
  .full-card-content-container {
    flex-direction: column;
    padding: 1vh;
    box-sizing: border-box;
  }
}

.deletion-modal {
  position: absolute;
  background-color: white;
  width: 40vw;
  left: 30vw;
  top: 45vh;
  padding: 2vw;
  box-shadow: 0 0 100vw rgba(0, 0, 0, 0.5);
}

.deletion-modal-button-group {
  display: flex;
  justify-content: space-around;
}

.deletion-modal-text {
  text-align: center;
  margin-bottom: 2vh;
}

.deletion-modal-button {
  padding: 1vw;
  border: 0;
  font-weight: bold;
}

.deletion-modal-confirm {
  background-color: #fc7f7a;
  color: white;
  font-weight: bold;
}

.dashboard-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.search-bar input::-webkit-input-placeholder {
  font-weight: bold;
}
.search-bar input:-ms-input-placeholder {
  font-weight: bold;
}
.search-bar input::placeholder {
  font-weight: bold;
}
.search-bar__field {
  flex: 1 1;
  display: flex;
  align-items: center;
  margin-right: 30px;
  min-height: 60px;
  padding: 16px 20px;
  font-size: 1.3125rem;
  box-sizing: border-box;
  background-color: #d4f6e8;
  border: none;
  font-weight: bold;
}

@media screen and (max-width: 1080px) {
  .search-bar {
    flex-direction: column;
  }
  .search-bar__field {
    width: 100%;
    min-height: 40px;
    padding: 10px;
    font-size: 1rem;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.cta-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  min-height: 60px;
  font-size: 1.3125rem;
  background-color: #5cd5b0;
  padding: 16px 20px;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.cta-button > * {
  margin-right: 20px;
}
.cta-button > *:last-child {
  margin-right: 0;
}
.cta-button--small {
  padding: 11px 12px;
  min-height: 40px;
  font-size: 1rem;
}

@media screen and (max-width: 1080px) {
  .cta-button {
    min-height: 40px;
    padding: 11px 12px;
    font-size: 1rem;
  }
  .cta-button--small {
    padding: 8px 10px;
    min-height: 30px;
    font-size: 0.75rem;
  }
}
.a-no-td {
  text-decoration: none;
}

.card-list {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
  gap: 60px;
}

@media screen and (max-width: 1080px) {
  .card-list {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    gap: 30px;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.checkbox__input {
  display: none;
}
.checkbox__box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
  border: 4px solid #5cd5b0;
  margin-right: 10px;
  box-sizing: border-box;
}
.checkbox__checkmark {
  margin-left: 4px;
}
.checkbox__label {
  pointer-events: none;
  font-size: 1rem;
}

.series-checkboxes {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.series-checkboxes__label {
  margin-bottom: 30px;
}
.series-checkboxes__checkboxes {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.series-checkboxes__checkboxes > * {
  margin-bottom: 20px;
}
.series-checkboxes__checkboxes > *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1080px) {
  .series-checkboxes__label {
    margin-bottom: 20px;
  }
  .series-checkboxes__checkboxes > * {
    margin-right: 20px;
  }
  .series-checkboxes__checkboxes > *:last-child {
    margin-right: 0;
  }
}
.comment {
  display: flex;
  flex-direction: column;
  background-color: #d4f6e8;
  padding: 20px;
  box-sizing: border-box;
}
.comment__username {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
}
.comment__content {
  margin-top: 10px;
}
.comment__likes-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.comment__likes-row .like-icon {
  font-size: 1.3125rem;
  margin-right: 10px;
  cursor: pointer;
}
.comment__like-count {
  font-size: 1.3125rem;
  color: #5cd5b0;
  font-weight: bold;
}

.cards-page {
  margin: 100px 20px;
}
.cards-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.cards-page__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}
.cards-page__title {
  margin-bottom: 80px;
}
.cards-page__series {
  margin-top: 60px;
}
.cards-page__card-list {
  max-width: 1400px;
  width: 100%;
  margin-top: 100px;
}

@media screen and (max-width: 1080px) {
  .cards-page__title {
    margin-bottom: 40px;
  }
  .cards-page__series {
    margin-top: 40px;
  }
  .cards-page__card-list {
    margin-top: 80px;
  }
}
.edit-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-title {
  margin: 2vh 0;
  padding: 1vw;
}

.edit-content {
  background-color: white;
  line-height: 150%;
}

.update-card-button {
  margin-top: 2vh;
  padding: 1vw;
  width: 20%;
  font-size: 1em;
}

.home {
  margin: 100px 0;
  padding: 0 20px;
}
.home__content {
  max-width: 1000px;
  margin: 0 auto;
}
.home__title {
  text-align: center;
  margin-bottom: 80px;
}
.home__video-section {
  width: 100%;
  height: calc(calc(min(1000px, 100vw - 40px)) * 0.5625);
  background-color: #d4f6e8;
  margin-bottom: 60px;
}
.home__explanation {
  margin-bottom: 60px;
}
.home__cards {
  display: flex;
  flex-direction: column;
}
.home__cards-text {
  margin: 0;
}
.home__card-ctas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.home__or {
  font-weight: bold;
  color: #5cd5b0;
  font-size: 1.3125rem;
  margin: 0 calc(min(4vw, 60px));
}

@media screen and (max-width: 1080px) {
  .home__title, .home__video-section, .home__explenation {
    margin-bottom: 40px;
  }
  .home__card-ctas {
    flex-direction: column;
  }
  .home__or {
    margin: 20px 0;
  }
}
.dashboard-container {
  padding-top: 5vh;
}
@media (max-width: 1450px) {
  .dashboard-container {
    padding: 3vw;
    box-sizing: border-box;
  }
}

.dashboard-title {
  text-align: center;
  font-size: 2em;
}

.edit-home-btn-logout-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5vh;
}
@media (max-width: 900px) {
  .edit-home-btn-logout-container {
    flex-direction: column;
  }
}

.edit-home-btn-title-container {
  display: flex;
  flex-direction: column;
}

.edit-home-page-link {
  padding: 0.8vw 1vw;
  color: #FAFAFA;
  text-decoration: none;
  font-weight: bold;
  border-radius: 0.2vw;
  background-color: #5cd5b0;
  margin-right: 1vw;
}

.logout-button {
  background-color: transparent;
  border: 0;
  color: #5cd5b0;
  font-weight: bold;
}
@media (max-width: 900px) {
  .logout-button {
    margin-top: 1vh;
  }
}

.edit-comment-confirm-container {
  margin-bottom: 8vh;
}
@media (max-width: 900px) {
  .edit-comment-confirm-container {
    flex-direction: column;
  }
}

.home-dash-section-title-comment {
  margin-bottom: 4vh;
}

.dashboard-card-series-label {
  background-color: transparent !important;
  width: 20%;
}

.dashboard-card-series-value {
  text-transform: uppercase;
}

.dashboard-card-thumb-title {
  margin: 0;
  color: #5cd5b0;
  width: 40%;
}
@media (max-width: 900px) {
  .dashboard-card-thumb-title {
    width: 100%;
    margin-bottom: 1vh;
  }
}

.dashboard-card-thumb-edit-icon {
  font-size: 1.6em;
  margin: 1vh 2vh;
}

.dashboard-card-thumb-edit-icon.edit {
  color: black;
}

.dashboard-card-thumb-edit-icon.delete {
  color: #FF5555;
}

.dashboard-card-thumb-like-container {
  display: flex;
  align-items: center;
}

.dashboard-card-thumb-like-text {
  color: #5cd5b0;
  font-size: 1.6em;
  margin: 0 0 0 1vh;
}

.dashboard-card-thumb-all-but-title {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vh;
}
@media (max-width: 900px) {
  .dashboard-card-thumb-all-but-title {
    width: 100%;
  }
}

.add-card-link {
  background-color: #5cd5b0;
  color: #FAFAFA;
  border: 0;
  padding: 0.8vw;
  border-radius: 0.5vh;
  font-weight: bold;
  font-size: 1em;
}

.dashboard-cards-section-title-add-btn {
  display: flex;
  justify-content: space-between;
}

.admin-comment {
  padding: 0 20px;
  margin-bottom: 100px;
}
.admin-comment__search {
  margin-top: 80px;
  margin-bottom: 140px;
}
.admin-comment__list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.admin-comment__top {
  display: flex;
  align-items: center;
}
.admin-comment__top > * {
  margin: 0;
}
.admin-comment__name {
  font-weight: bold;
}
.admin-comment__email {
  margin-left: 10px;
}
.admin-comment__button-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}
.admin-comment__button-row .delete-icon {
  color: #fc7f7a;
  margin-left: 20px;
  cursor: pointer;
}
.admin-comment__likes {
  color: #5cd5b0;
  margin-right: 30px;
  font-weight: bold;
}
.admin-comment__like-icon {
  margin-right: 6px;
}

.admin-comment-list-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.admin-comment-list-title {
  margin: 5vh 0 2vh 0;
  text-align: center;
}

.admin-comment-container {
  display: flex;
  flex-direction: column;
  background-color: #d4f6e8;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 1080px) {
  .admin-comment__list-container {
    grid-template-columns: 1fr;
  }
}
.edit-home-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-top: 5vh;
  box-sizing: border-box;
}
.edit-home-wrapper .deletion-modal {
  z-index: 10;
}

.edit-home-title {
  color: #5cd5b0;
  font-size: 1.5em;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.edit-home-container {
  background-color: #d4f6e8;
  padding: 30px;
  padding-top: 1vh;
  box-sizing: border-box;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.edit-home-container h4 {
  margin-bottom: 10px;
  margin-top: 30px;
  color: #5cd5b0;
}

.home-content-main-textarea {
  width: 100%;
  min-height: 15vh;
  font-family: Roboto, sans-serif;
  line-height: 150%;
}

.file-input {
  width: 100%;
  border: 0;
}

.edit-home-delete,
.edit-home-save {
  padding: 0.4vw 1vw;
  background-color: #5cd5b0;
  border: none;
  border-radius: 0.2vw;
  color: #FAFAFA;
  font-weight: bold;
  cursor: pointer;
}

.edit-home-delete {
  display: flex;
  align-items: center;
  background-color: #fc7f7a;
  margin-right: 30px;
  color: white;
}
.edit-home-delete .delete-icon {
  margin-right: 10px;
}

.edit-home-save-flex-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.video-url-input {
  margin-bottom: 2vh;
  width: 100%;
  box-sizing: border-box;
}

.edit-home-content-btn-cont {
  margin-top: 2vh;
}

.post-confirm-button {
  background-color: #5cd5b0;
}

.post-confirm-text {
  text-align: left;
}

.card-detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 80px auto;
  grid-gap: 40px;
  gap: 40px;
}
.card-detail__image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.card-detail__likes {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.card-detail__like-icon {
  cursor: pointer;
}
.card-detail__likes-count {
  font-size: 1.5625rem;
  font-weight: bold;
  color: #5cd5b0;
  margin-left: 10px;
}
.card-detail__extra {
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  word-break: break-word;
}
.card-detail .comment-section .create-comment {
  display: flex;
  flex-direction: column;
  background-color: #d4f6e8;
  padding: 30px;
}
.card-detail .comment-section .create-comment__title {
  font-size: 1.3125rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1.5rem;
}
.card-detail .comment-section .create-comment__value-container {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.card-detail .comment-section .create-comment__value-container:last-child {
  margin-bottom: 0;
}
.card-detail .comment-section .create-comment__label {
  display: flex;
  word-break: break-word;
  width: calc(min(160px, 10vw));
  min-width: 132px;
  font-weight: bold;
  color: #62AD9B;
  margin-right: 10px;
  font-size: 1rem;
}
.card-detail .comment-section .create-comment__input {
  width: 40%;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
}
.card-detail .comment-section .create-comment__input::-webkit-input-placeholder {
  color: #62AD9B;
  font-weight: normal;
}
.card-detail .comment-section .create-comment__input:-ms-input-placeholder {
  color: #62AD9B;
  font-weight: normal;
}
.card-detail .comment-section .create-comment__input::placeholder {
  color: #62AD9B;
  font-weight: normal;
}
.card-detail .comment-section .create-comment__input--textarea {
  flex: 1 1;
}
.card-detail .comment-section .create-comment__input--textarea::-webkit-input-placeholder {
  color: #62AD9B;
  font-weight: normal;
}
.card-detail .comment-section .create-comment__input--textarea:-ms-input-placeholder {
  color: #62AD9B;
  font-weight: normal;
}
.card-detail .comment-section .create-comment__input--textarea::placeholder {
  color: #62AD9B;
  font-weight: normal;
}
.card-detail .comment-section .create-comment__post-comment {
  box-sizing: border-box;
  padding: 10px 20px;
  border: 0;
  background-color: #5cd5b0;
  color: #FAFAFA;
  font-weight: bold;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.card-detail .comment-section__message {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  color: #62AD9B;
}
.card-detail .comment-section__seperator {
  height: 2px;
  width: 100%;
  background-color: #5cd5b0;
  margin: 30px 0;
}
.card-detail .comment-section__comment-list > * {
  margin-bottom: 30px;
}
.card-detail .comment-section__comment-list > *:last-child {
  margin-bottom: 0;
}

.like-icon {
  font-size: 2em;
  color: #5cd5b0;
}

@media screen and (max-width: 1080px) {
  .card-detail {
    grid-template-columns: 1fr;
  }
  .card-detail .comment-section .create-comment {
    align-items: center;
    padding: 20px;
  }
  .card-detail .comment-section .create-comment__value-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 400px;
    width: 100%;
    flex-direction: column;
  }
  .card-detail .comment-section .create-comment__label {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .card-detail .comment-section .create-comment__input {
    width: 100%;
  }
}
.create-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
}
.create-card__left, .create-card__right {
  display: flex;
  flex-direction: column;
}
.create-card__left > .ck .ck.ck-toolbar > .ck-toolbar__items {
  flex-wrap: wrap;
  font-size: 0.55rem;
}
.create-card__file-input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: #5cd5b0;
  font-weight: bold;
  margin-bottom: 30px;
}
.create-card__file-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.create-card__file-input-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}
.create-card__file-input-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1080px) {
  .create-card {
    display: grid;
    grid-template-columns: 1fr;
  }
  .create-card__file-input-container {
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.login-page {
  padding: 0 20px;
}

.login-form {
  background-color: #d4f6e8;
  width: 100%;
  max-width: 400px;
  margin: 10vh auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
}

.login-form h2 {
  color: #5cd5b0;
  text-align: center;
}

.auth-form-label {
  display: flex;
  flex-direction: column;
  color: #5cd5b0;
  margin-bottom: 20px;
  font-weight: bold;
}

.auth-form-label input {
  border: 0;
  padding: 10px;
  margin-top: 10px;
}

.login-form button {
  padding: 10px;
  border: 0;
  background-color: #5cd5b0;
  color: #FAFAFA;
  text-transform: uppercase;
  font-size: 1.3125rem;
  font-weight: bold;
  cursor: pointer;
}

.auth-error-message {
  background-color: #FF5555;
  color: #FAFAFA;
  padding: 0.5vh;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 2vh;
}

@media screen and (max-width: 1080px) {
  .login-form {
    padding: 40px 20px;
  }
}
.nav {
  background-color: #D4F6E8;
  width: 100%;
  margin: 0 auto;
  padding: 2vh 0;
}
@media (max-width: 1450px) {
  .nav {
    padding: 1vh 3vw;
    box-sizing: border-box;
  }
}

.nav-inner {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 850px) {
  .nav-inner {
    display: none;
  }
}

.logo {
  background-image: url("https://www.potentialtoteach.be/src/Frontend/Themes/Bootstrap/Core/Layout/Images/Site/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.logo-link {
  width: 10%;
  height: 5vh;
}

.links {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}

.links a {
  text-decoration: none;
  color: black;
}

.mobile-nav {
  width: 100%;
  height: 6.5vh;
}
@media (min-width: 850px) {
  .mobile-nav {
    display: none;
  }
}

.mobile-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  height: 6.5vh;
  padding: 0 3vh;
  box-sizing: border-box;
}

.mobile-logo {
  width: 25%;
  height: 5vh;
  background-size: contain;
}

.menu-logo {
  width: 70%;
  margin-top: 4vh;
  height: 20vh;
}

.mobile-nav-menu-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  left: 0;
  top: 0;
}

.mobile-nav-menu {
  width: 50%;
  height: 100%;
  background-color: #FAFAFA;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.mobile-links {
  width: 100%;
  height: 30%;
  padding-left: 15%;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}

.mobile-nav-closer {
  width: 50%;
  height: 100%;
  background-color: transparent;
}

.mobile-nav-bar-button {
  font-size: 1.3em;
}

/*# sourceMappingURL=App.css.map */

